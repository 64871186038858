import React from 'react';

import LandingLayout from '../../components/LandingLayout/LandingLayout';
import TermsAndConditions from './TermsAndConditions';

const TermsAndConditionsPage = () => {
  return (
    <LandingLayout>
      <TermsAndConditions />
    </LandingLayout>
  );
};
export default TermsAndConditionsPage;
