import { useSelector } from 'react-redux';

import { translateField } from '../../../shared/utils';

import { TitleNotification } from '../../../shared/commonStyles';
import { Verification } from '../Verification/Verification';

export const Success = () => {
  const signUpActive = useSelector((state) => state.auth.signUpActive);

  const words = useSelector((state) => state.words);

  const message = (status) => {
    switch (status) {
      case 'redirect':
        return null;
      case 'email':
        return translateField('check_your_email', 'auth.message', words, false);
      case 'phone':
        return translateField('check_your_phone', 'auth.message', words, false);
      default:
        return translateField('check_your_email', 'auth.message', words, false);
    }
  };

  return (
    <div className="auth-form">
      <div className="auth-form_title">{translateField('registration', 'auth', words, false)}</div>

      <div className="auth-form_wrapper">
        {signUpActive?.activation === 'phone' ? (
          <Verification registration handleVerify={{ data: 'phone', step: 'send', fieldValue: null, open: true }} />
        ) : (
          <TitleNotification>{message(signUpActive)}</TitleNotification>
        )}
      </div>
    </div>
  );
};
