import { GET_BONUS_HISTORY } from '../actions/actionTypes';

const initialState = {
  bonusHistory: null,
};

const bonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BONUS_HISTORY:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default bonusReducer;
