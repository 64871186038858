export const SET_PAGE_METADATA = 'SET_PAGE_METADATA';

export const GET_WORDS = 'GET_WORDS';
export const SET_WORDS = 'SET_WORDS';
export const GET_WORDS_ERROR = 'GET_WORDS_ERROR';

export const SET_LOCAL_WORDS = 'SET_LOCAL_WORDS';

export const SET_NOTIFICATION_READ = 'SET_NOTIFICATION_READ';
export const SET_NOTIFICATION_READ_ERROR = 'SET_NOTIFICATION_READ_ERROR';

export const FETCH_DATA = 'FETCH_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_DATA_LOADING = 'UPDATE_DATA_LOADING';
export const BONUS_DETAILS_INFO = 'BONUS_DETAILS_INFO';
export const FETCH_DATA_ERROR = 'UPDATE_DATA';
export const GET_TECHNICAL_DATA = 'GET_TECHNICAL_DATA';

export const GET_WINDOW_SIZE = 'GET_WINDOW_SIZE';
export const GET_ACTIVE_GAMELIST = 'GET_ACTIVE_GAMELIST';
export const SET_SEARCH_GAMELIST = 'SET_SEARCH_GAMELIST';
export const GET_ACTIVE_POPUP = 'GET_ACTIVE_POPUP';

export const SIGNIN_TYPE = 'SIGNIN_TYPE';
export const SIGNIN_TYPE_SUCCESS = 'SIGNIN_TYPE_SUCCESS';
export const SIGNIN_TYPE_ERROR = 'SIGNIN_TYPE_ERROR';
export const SET_CURRENT_USER_TOKEN = 'SET_CURRENT_USER_TOKEN';
export const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
export const GET_IP_ERROR = 'GET_IP_ERROR';
export const GET_USERAGENT_SUCCESS = 'GET_USERAGENT_SUCCESS';

export const SIGNOUT_TYPE = 'SIGNOUT_TYPE';
export const SIGNOUT_TYPE_SUCCESS = 'SIGNOUT_TYPE_SUCCESS';
export const SIGNOUT_TYPE_ERROR = 'SIGNOUT_TYPE_ERROR';

export const SIGNUP_TYPE = 'SIGNUP_TYPE';
export const SIGNUP_TYPE_SUCCESS = 'SIGNUP_TYPE_SUCCESS';
export const SIGNUP_PHONE_NUMBER = 'SIGNUP_PHONE_NUMBER';
export const SIGNUP_TYPE_ACTIVATION = 'SIGNUP_TYPE_ACTIVATION';
export const SIGNUP_TYPE_ERROR = 'SIGNUP_TYPE_ERROR';

export const GET_INFO = 'GET_INFO';
export const SET_INFO = 'SET_INFO';
export const GET_INFO_ERROR = 'GET_INFO_ERROR';

export const CLOSE_GAME = 'CLOSE_GAME';
export const OPEN_GAME = 'OPEN_GAME';
export const SET_OPEN_GAME = 'SET_OPEN_GAME';
export const GET_INFO_GAME = 'GET_INFO_GAME';
export const OPEN_GAME_ERROR = 'OPEN_GAME_ERROR';
export const SET_CLOSE_GAME = 'SET_CLOSE_GAME';
export const CLOSE_GAME_ERROR = 'CLOSE_GAME_ERROR';
export const GET_GAME_HISTORY = 'GET_GAME_HISTORY';
export const SET_GAME_HISTORY = 'SET_GAME_HISTORY';
export const GAME_HISTORY_ERROR = 'GAME_HISTORY_ERROR';

export const GET_BONUS_HISTORY = 'GET_BONUS_HISTORY';
export const SET_BONUS_HISTORY = 'SET_BONUS_HISTORY';
export const BONUS_HISTORY_ERROR = 'BONUS_HISTORY_ERROR';

export const FAVORITE_GAME = 'FAVORITE_GAME';
export const SET_FAVORITE_GAME = 'SET_FAVORITE_GAME';
export const FAVORITE_GAME_ERROR = 'FAVORITE_GAME_ERROR';

export const GET_PAYMENT = 'GET_PAYMENT';
export const SET_PAYMENT = 'SET_PAYMENT';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';
export const GET_PAYMENT_DEPOSIT = 'GET_PAYMENT_DEPOSIT';
export const GET_PAYMENT_WITHDRAW = 'GET_PAYMENT_WITHDRAW';
export const SET_PAYMENT_DEPOSIT = 'SET_PAYMENT_DEPOSIT';
export const SET_PAYMENT_WITHDRAW = 'SET_PAYMENT_WITHDRAW';
export const GET_PAYMENT_ERROR_DEPOSIT = 'GET_PAYMENT_ERROR_DEPOSIT';
export const GET_PAYMENT_ERROR_WITHDRAW = 'GET_PAYMENT_ERROR_WITHDRAW';
export const GET_BALANCE = 'GET_BALANCE';
export const SET_BALANCE = 'SET_BALANCE';
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR';

export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const ACCOUNT_UPDATE_INFO = 'ACCOUNT_UPDATE_INFO';
export const CLEAR_INTERVAL_INFO = 'CLEAR_INTERVAL_INFO';
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR';
export const ACCOUNT_UPDATE_FINISHED = 'ACCOUNT_UPDATE_FINISHED';
export const WALLET_ADD = 'WALLET_ADD';
export const WALLET_ADD_LOADING = 'WALLET_ADD_LOADING';
export const PASSWORD_RESET_LOADING = 'PASSWORD_RESET_LOADING';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const PASSWORD_RESET_ADD = 'PASSWORD_RESET_ADD';
export const WALLET_ADD_ERROR = 'WALLET_ADD_ERROR';

export const SET_ACTIVE_INPUT = 'SET_ACTIVE_INPUT';

export const SET_ACTIVE_FORM = 'SET_ACTIVE_FORM';

export const SET_VARIABLE_GAMECARD = 'SET_VARIABLE_GAMECARD';

export const SET_OPEN_MENU = 'SET_OPEN_MENU';
export const SET_OPEN_NOTIFICATIONS = 'SET_OPEN_NOTIFICATIONS';
export const SET_TOGGLE_NOTIFICATIONS = 'SET_TOGGLE_NOTIFICATIONS';

export const GET_VERIVICATION = 'GET_VERIVICATION';

export const SET_VERIVICATION = 'SET_VERIVICATION';
export const GET_VERIVICATION_ERROR = 'GET_VERIVICATION_ERROR';

export const ACTIVATE_BONUS = 'ACTIVATE_BONUS';
export const CANCEL_BONUS = 'CANCEL_BONUS';

export const GET_WALLET_QUICK_ACCESS = 'GET_WALLET_QUICK_ACCESS';

export const SET_ACTIVE_TAB_NOTIFICATION = 'SET_ACTIVE_TAB_NOTIFICATION';
export const TOGGLE_CONTENT_NOTIFICATION = 'TOGGLE_CONTENT_NOTIFICATION';

export const RESET_CLOSED_GAME_ID = 'RESET_CLOSED_GAME_ID';
export const SET_HEADER = 'SET_HEADER';
export const SET_NAVIGATION = 'SET_NAVIGATION';

export const SET_AXIOS_ERROR = 'SET_AXIOS_ERROR';
export const SET_HANDLE_SCROLL = 'SET_HANDLE_SCROLL';
export const SET_HANDLE_BONUS = 'SET_HANDLE_BONUS';
export const SET_HANDLE_PATH_BONUS = 'SET_HANDLE_PATH_BONUS';
export const SET_UPDATE_INFO_USER = 'SET_UPDATE_INFO_USER';

export const SET_WINNERS_LIST = 'SET_WINNERS_LIST';

export const SET_TOURNAMENTS = 'SET_TOURNAMENTS';
export const SET_TOURNAMENTS_LOADING = 'SET_TOURNAMENTS_LOADING';
export const SET_TOURNAMENTS_ERROR = 'SET_TOURNAMENTS_ERROR';
export const GET_CHANGE_LANGUAGE = 'GET_CHANGE_LANGUAGE';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const GET_BUILD_BLOCKS = 'GET_BUILD_BLOCKS';
