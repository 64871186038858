import { memo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';
import { YellowTitle } from '../_common/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media (max-width: 776px) {
    margin-bottom: 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  justify-content: space-between;
`;

const Item = styled.div`
  width: calc(50% - 25px);
  display: flex;
  flex-direction: column;

  @media (max-width: 776px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const ItemTitle = styled.h3`
  color: var(--bonuses-card-title_color, #6ec1e4);
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 35px;
  line-height: 1.3em;
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
`;

const Description = styled.div`
  color: var(--bonuses-card-text_color, #7a7a7a);
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 600;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 15px;
  }
`;

const Bonuses = memo(({ setShowRegBlock }) => {
  const words = useSelector((state) => state.words);
  const bonusesList = useSelector((state) => state.settings?.homeBonusesList) || [];

  return (
    <Container id="bonuses">
      <YellowTitle>{translateField('bonuses_title', 'bonuses', words)}</YellowTitle>
      <Wrapper>
        {bonusesList.map((bonus, i) => (
          <Item onClick={() => setShowRegBlock(true)} key={i}>
            <Img src={bonus.img} alt={translateField(bonus.meta_alt, 'bonuses', words)} />
            <ItemTitle>{translateField(bonus.head, 'bonuses', words)}</ItemTitle>
            <Description>{translateField(bonus.text, 'bonuses', words)}</Description>
          </Item>
        ))}
      </Wrapper>
    </Container>
  );
});

export default Bonuses;
