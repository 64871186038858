import React from 'react';

import LandingLayout from '../../components/LandingLayout/LandingLayout';
import Faq from './Faq';

const FaqPage = () => {
  return (
    <LandingLayout>
      <Faq />
    </LandingLayout>
  );
};

export default FaqPage;
