import LandingLayout from '../../components/LandingLayout/LandingLayout';
import Games from './Games';

const GamesPage = () => {
  return (
    <LandingLayout>
      <Games />
    </LandingLayout>
  );
};

export default GamesPage;
