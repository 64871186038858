// reducers/apiReducer.js

import {
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_INFO,
  BONUS_DETAILS_INFO,
  FETCH_DATA,
  FETCH_DATA_ERROR,
  UPDATE_DATA,
  SET_AXIOS_ERROR,
  UPDATE_DATA_LOADING,
  SET_FAVORITE_GAME,
  SET_UPDATE_INFO_USER,
  SET_BONUS_HISTORY,
  SET_GAME_HISTORY,
  SET_BALANCE,
  GET_TECHNICAL_DATA,
  GET_INFO_GAME,
  CLEAR_INTERVAL_INFO,
  ACCOUNT_UPDATE_FINISHED,
  GET_CHANGE_LANGUAGE,
  GET_BUILD_BLOCKS,
} from '../actions/actionTypes';

const initialState = {
  loadingUpdateData: false,
  loading: null,
  error: null,
  errorUpdatePass: null,
  bonusHistory: null,
  gameHistory: null,
  technicalData: null,
  balanceHistory: null,
  intervalUpdate: null,
  accountUpdateFinished: false,
  account: {
    id: null,
    notification: [],
  },
  page: {
    blocks: {
      tabs: [],
      main: [],
    },
  },
  bonusDetailsInfo: null,
  axios: {
    error: null,
  },
  gamesList: {
    favorites: [],
  },
  language: 'en',
};

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        ...action.payload,
        blocks: {
          tabs: Boolean(action.payload?.page?.blocks?.length) && action.payload?.page?.url === '/profile' ? action.payload?.page?.blocks : state.page.blocks.tabs,
          main: Boolean(action.payload?.page?.blocks?.length) && action.payload?.page?.url === '/' ? action.payload?.page?.blocks : state.page.blocks.main,
        },
      };
    case SET_UPDATE_INFO_USER:
      return {
        ...state.profile,
        // ...getValues(),
      };
    case ACCOUNT_UPDATE:
      return {
        ...state,
        ...action.payload,
        page: {
          ...state.page,
          ...action.payload?.page,
          blocks: {
            tabs: Boolean(action.payload?.page?.blocks?.length) && action.payload?.page?.url === '/profile' ? action.payload?.page?.blocks : state.page.blocks.tabs,
            main: Boolean(action.payload?.page?.blocks?.length) && action.payload?.page?.url === '/' ? action.payload?.page?.blocks : state.page.blocks.main,
          },
        },
        loading: true,
      };
    case ACCOUNT_UPDATE_INFO:
      return {
        ...state,
        account: action.payload?.account,
        bonuses: action.payload?.bonuses,
        jackpots: action.payload?.jackpots,
        intervalUpdate: action.payload?.intervalUpdate,
        page: {
          ...state?.page,
          blocks: state.page.blocks,
        },
      };
    case CLEAR_INTERVAL_INFO:
      return {
        ...state,
        intervalUpdate: null,
      };
    case GET_INFO_GAME:
      return {
        ...state,
        page: {
          ...state.page,
          info: action.payload,
        },
      };
    case ACCOUNT_UPDATE_ERROR:
      return {
        ...state,
        ...action.payload,
        errorUpdatePass: action.payload,
      };
    case ACCOUNT_UPDATE_FINISHED:
      return {
        ...state,
        accountUpdateFinished: action.payload,
      };
    case UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
        account: {
          ...action.payload?.account,
          notification: action.payload?.account?.notification,
        },
        page: {
          ...action.payload?.page,
          blocks: {
            tabs: Boolean(action.payload?.page?.blocks?.length) && action.payload?.page?.url === '/profile' ? action.payload?.page?.blocks : state.page.blocks.tabs,
            main: Boolean(action.payload?.page?.blocks?.length) ? action.payload?.page?.blocks : state.page.blocks.main,
          },
        },
        loading: true,
      };
    case GET_TECHNICAL_DATA:
      return {
        ...state,
        page: {
          ...state?.page,
          info: action.payload,
          blocks: state.page.blocks,
        },
      };
    case UPDATE_DATA_LOADING:
      return {
        ...state,
        loadingUpdateData: action.payload,
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case BONUS_DETAILS_INFO:
      return {
        ...state,
        bonusDetailsInfo: action.payload,
        page: {
          ...state?.page,
          blocks: state.page.blocks,
        },
      };
    case SET_AXIOS_ERROR:
      return {
        ...state,
        axios: {
          error: action.payload,
        },
      };
    case SET_FAVORITE_GAME:
      return {
        ...state,
        gamesList: {
          ...state.gamesList,
          favorites: action.payload,
        },
      };
    case SET_BONUS_HISTORY:
      return {
        ...state,
        bonusHistory: action.payload,
        page: {
          ...state?.page,
          blocks: state.page.blocks,
        },
      };
    case SET_GAME_HISTORY:
      return {
        ...state,
        gameHistory: action.payload,
        page: {
          ...state?.page,
          blocks: state.page.blocks,
        },
      };
    case SET_BALANCE:
      return {
        ...state,
        balanceHistory: action.payload,
        page: {
          ...state?.page,
          blocks: state.page.blocks,
        },
      };
    case GET_CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case GET_BUILD_BLOCKS:
      return {
        ...state,
        page: {
          ...state.page,
          blocks: {
            ...state.page.blocks,
            main: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default apiReducer;
