import { useState } from 'react';
import { useSelector } from 'react-redux';

import ReactInlineSvg from 'react-inlinesvg';

import { CheckboxCustom } from '../../elements';
import { translateField } from '../../shared/utils';

import { FieldInput } from '../../components/Auth/assets/FormStyles';

/**
 * Note: Компонент AcceptTerms - компонент для отображения и обработки согласия с условиями.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {function} props.onChange - Обработчик изменения согласия.
 * @param {string} props.error - Сообщение об ошибке.
 * @param {boolean} props.termsChecked - Флаг согласия.
 * @returns {JSX.Element} - Возвращает разметку компонента AcceptTerms.
 */
export const AcceptTerms = ({ onChange, error, termsChecked }) => {
  const words = useSelector((state) => state.words);

  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  /**
   * Обработчик изменения согласия.
   */
  const handleChange = () => {
    setTermsError(false); // Сбрасываем ошибку
    setTerms(!terms); // Изменяем согласие
  };

  // redirect_link

  return (
    <FieldInput className="auth-form-terms" key="terms">
      <CheckboxCustom checked={terms || termsChecked} onChange={onChange ?? handleChange} required />
      <span>
        <ReactInlineSvg src="/images/icons/21-plus.svg" />
        {translateField('accept', 'auth', words, false)}
        <a href={`${translateField('redirect_link', 'roulette', words, false)}terms`}>{translateField('terms_and_conditions', 'pages', words, false)}</a>
      </span>
      {(termsError || error) && <div className="error error--terms">You must accept the terms and conditions.</div>}
    </FieldInput>
  );
};
