// Импорт необходимых библиотек и хуков
import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { useLocation } from 'react-router-dom';
import { translateField } from '../../shared/utils';

/**
 * Note: Компонент Tab - представляет собой вкладки (табы) для переключения между различными разделами контента.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.tabs - Список вкладок, каждая из которых представляет объект с id, name и comp (компонент для отображения).
 * @param {number} props.activeTab - Индекс текущей активной вкладки.
 * @param {number} props.activeWallet - Индекс активного кошелька (возможно, не используется в текущей версии компонента).
 * @param {Function} props.onTabChange - Функция обработки смены активной вкладки.
 * @param {string} props.className - Дополнительные классы для стилизации компонента.
 * @param {Object} props.navIsSlider - Параметры настройки слайдера Swiper для навигации.
 * @param {string} props.navIsSlider.slidesPerView - Количество видимых слайдов.
 * @param {string} props.navIsSlider.direction - Направление слайдов (горизонтальное или вертикальное).
 * @param {boolean} props.navIsSlider.borderBottom - Наличие подчеркивания у вкладок.
 * @param {boolean} props.navIsSlider.arrow - Наличие стрелок для навигации.
 * @param {boolean} props.navIsSlider.arrowRow - Наличие стрелок в ряду.
 * @param {string} props.navIsSlider.spaceBetween - Расстояние между слайдами.
 *
 */

// Определение компонента Tab
export const Tab = ({
  tabs, // Список вкладок, каждая из которых представляет объект с id, name и comp (компонент для отображения)
  activeTab, // Активная вкладка
  activeTabChange, // Активный кошелек (не используется в текущей версии компонента)
  onTabChange, // Функция обработки смены активной вкладки
  className = '', // Дополнительные классы для стилизации
  styled = '', // Стилизация компонента (не используется в текущей версии компонента)
  navIsSlider = {
    // Параметры настройки слайдера Swiper для навигации
    slidesPerView: 'auto',
    direction: 'horizontal',
    borderBottom: false,
    arrow: true,
    arrowRow: false,
    spaceBetween: '',
  },
  hidden = false, // Флаг, указывающий, нужно ли отображать вкладку
}) => {
  const size = useSelector((state) => state.handling.size); // Получение размера из Redux-хранилища
  const location = useLocation();
  const [swiperInstance, setSwiperInstance] = useState(null); // Состояние для хранения экземпляра Swiper
  const swiperRef = useRef(null);
  const words = useSelector((state) => state.words); // Получение слов из Redux-хранилища

  // Эффект для смены активной вкладки при изменении активного кошелька (возможно, не используется в текущей версии)
  useEffect(() => {
    if (activeTabChange) {
      onTabChange(Number(activeTabChange));
      location.state = { TabsProfileGeneral: undefined };
    }
  }, [activeTab, activeTabChange, location, onTabChange]);

  // Создание настроек Swiper с использованием useMemo
  const swiperSettings = useMemo(() => {
    return {
      slidesPerView: `${navIsSlider.slidesPerView}`,
      initialSlide: activeTab,
      autoHeight: false,
      height: 'auto',
      spaceBetween: navIsSlider.spaceBetween !== undefined ? navIsSlider.spaceBetween : size.mobile ? 10 : 20,
      speed: 1000,
      modules: [Navigation],
      direction: navIsSlider.direction,
      navigation: {
        disabledClass: 'disable',
        nextEl: `${navIsSlider.nextEl ? `.${navIsSlider.nextEl}` : ''}`,
        prevEl: `${navIsSlider.prevEl ? `.${navIsSlider.prevEl}` : ''}`,
      },
    };
  }, [activeTab, size, navIsSlider]);

  // Эффект для синхронизации активной вкладки с Swiper
  useEffect(() => {
    if (swiperInstance && swiperRef.current) {
      swiperInstance.slideTo(activeTab);
    }
  }, [activeTab, swiperInstance]);

  // Создание компонента навигации в виде слайдера
  const navigationSlider = useMemo(() => {
    return (
      <div
        className={classNames('tab__nav', {
          'border-bottom': navIsSlider.borderBottom,
          'nav-is-slider': navIsSlider.arrowRow,
        })}
      >
        {navIsSlider.arrow && (
          <div className="swiper-nav">
            <img
              className={classNames('swiper-arrow swiper-arrow-prev', {
                [navIsSlider.prevEl]: navIsSlider.prevEl,
              })}
              src="/images/icons/arrow.svg"
              style={{ width: '20px', height: '20px' }}
              alt="next"
            />
          </div>
        )}

        <Swiper
          {...swiperSettings}
          className={classNames('tab__swiper', {
            general: styled === 'general',
            [styled]: styled,
          })}
          onSwiper={(swiper) => {
            setSwiperInstance(swiper);
            swiperRef.current = swiper;
          }}
        >
          {tabs.map((tab, index) => (
            <SwiperSlide
              key={tab.id}
              className={classNames('name', {
                active: index === activeTab,
              })}
              onClick={() => onTabChange(index)}
            >
              {translateField(tab.name, 'tabs.list', words, false)}
            </SwiperSlide>
          ))}
        </Swiper>

        {navIsSlider.arrow && (
          <div className="swiper-nav">
            <img
              className={classNames('swiper-arrow swiper-arrow-next', {
                [navIsSlider.nextEl]: navIsSlider.nextEl,
              })}
              src="/images/icons/arrow.svg"
              style={{ width: '20px', height: '20px', transform: 'rotate(180deg)' }}
              alt="next"
            />
          </div>
        )}
      </div>
    );
  }, [activeTab, onTabChange, styled, swiperSettings, tabs, navIsSlider, words]);

  // Создание компонента навигации в виде списка
  const navigationDefault = useMemo(() => {
    return (
      <div
        className={classNames('tab__nav', {
          general: styled === 'general',
          [styled]: styled,
        })}
      >
        {tabs.map((tab, index) => {
          if (tab.hidden) return null;
          return (
            <div
              key={tab.id}
              className={classNames('name', {
                active: index === activeTab,
              })}
              onClick={() => onTabChange(index)}
            >
              {translateField(tab.name, 'tabs.list', words, false)}
            </div>
          );
        })}
      </div>
    );
  }, [activeTab, onTabChange, styled, tabs, words]);

  // Возвращение разметки компонента Tab
  return (
    <div className={`tab ${className}`}>
      {navIsSlider ? navigationSlider : navigationDefault}
      <div className="tab__content">{tabs[activeTab]?.comp}</div>
    </div>
  );
};
