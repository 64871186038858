import React from 'react';
import { useEffect, useState } from 'react';
import LandingLayout from '../../components/LandingLayout/LandingLayout';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => {
  const [showRegBlock, setShowRegBlock] = useState(false);

  useEffect(() => {
    const isOpen = window?.settings?.is_form_open;

    if (isOpen) {
      setShowRegBlock(true);
    }
  }, []);

  useEffect(() => {
    if (showRegBlock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [showRegBlock]);

  return (
    <LandingLayout>
      <PrivacyPolicy />
    </LandingLayout>
  );
};

export default PrivacyPolicyPage;
