import React from 'react';
import LandingLayout from '../../components/LandingLayout/LandingLayout';
import About from './About';

const AboutPage = () => {
  return (
    <LandingLayout>
      <About />
    </LandingLayout>
  );
};

export default AboutPage;
