import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchData } from '../../store/actions';
import { SignUpForm } from './SignUpForm/SignUpForm';
import { Success } from './Success/Success';

export const Auth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authFormType = useSelector((state) => state.auth.activeForm);
  const signUpData = useSelector((state) => state.api?.registration);

  useEffect(() => {
    if (location.pathname === '/auth' && !signUpData) {
      dispatch(fetchData('/auth'));
    }
  }, [dispatch, location.pathname, signUpData]);

  useEffect(() => {
    localStorage.setItem('activeForm', authFormType || 'login');
    return () => localStorage.removeItem('activeForm');
  }, [authFormType]);

  const renderForm = (type) => {
    switch (type) {
      case 'registration':
        return <SignUpForm />;
      case 'success':
        return <Success />;
      default:
        return <SignUpForm />;
    }
  };
  return renderForm(authFormType);
};
