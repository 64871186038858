import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 100px;

  @media (max-width: 776px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3 {
    color: #fff;
  }

  p {
    color: #fff;
  }
`;

const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

const Item = styled.div``;

const ItemTitle = styled.h3`
  color: #f2fbf2;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 10px;

  @media (max-width: 776px) {
    font-size: 18px;
  }
`;

const Description = styled.pre`
  color: #f2fbff;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 15px;
  white-space: pre-wrap;
`;

const PrivacyPolicy = () => {
  const words = useSelector((state) => state.words);
  const privacyList = useSelector((state) => state.settings?.privacyList) || [];
  const title = translateField('privacy_title', 'privacy', words);
  const subtitle = translateField('privacy_subtitle', 'privacy', words);

  return (
    <Container id="privacyPolicy">
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <PrivacyContainer>
        {privacyList.map((privacy, i) => (
          <Item key={i}>
            <ItemTitle>{translateField(privacy.title, 'privacy', words)}</ItemTitle>
            <Description>{translateField(privacy.text, 'privacy', words)}</Description>
          </Item>
        ))}
      </PrivacyContainer>
    </Container>
  );
};

export default PrivacyPolicy;
