import { SET_ACTIVE_TAB_NOTIFICATION, TOGGLE_CONTENT_NOTIFICATION } from '../actions/actionTypes';

const TABS = [{ view: 'systemMessages', label: 'System Messages', icon: 'mail' }];

const SYSTEM_MESSAGES = [
  {
    id: '3646816928710933248',
    insertTime: 1695325628000,
    title: 'New device has been authorized',
    content:
      'You have successfully authorized a new device or in a new location to sign into your Binance account. \r\n\r\nLocation：Yerevan Armenia\r\nIP Address：000.000.000.00\r\nDevice：Chrome V116.0.0.0 (Windows)\r\n\r\nIf this activity is not your own operation, please disable your account and contact us immediately.',
  },
  {
    id: '3455577452299491334',
    insertTime: 1683926866000,
    title: 'Re-start with small amounts',
    content: 'Get your rewards by: \nMaking a $10 deposit for $30 Trading Fee Rebate voucher\n\nClick [View more] to explore your $30 reward.\n',
  },
  {
    id: '3462425654581539585',
    insertTime: 1684335051000,
    title: '[Important Notice] Your Deposit Address is About to Expire',
    content:
      'To provide better efficiency and security to your funds, some technical changes to our infrastructure\nhave been made. Your deposit address under BNB network will expire on 2023-06-16 14:41:27 UTC. \n\nNote: The below networks utilize the same deposit address and will also expire.\n[BNB]\n\nWhat you need to do:\nPlease obtain a new deposit address by selecting BNB network through Binance App/Web on the Deposit Crypto page, or through SAPI as soon as possible.\nhttps://www.binance.com/en/my/wallet/account/main/deposit/crypto\n\nWhat do I do if I’ve used the old address?\nAll past and subsequent deposits into old addresses are safe and will not be lost. Deposits to expired addresses can be credited by manually clicking the ‘One Click Arrival’ button from the Transaction History page.\n\nPlease visit the below FAQ for more information.\nhttps://www.binance.com/en/support/faq/853aaedc7f45430283abc7a25713c428',
  },
];

const initialState = {
  tabs: TABS,
  activeTab: TABS[0],
  systemMessages: {
    messages: SYSTEM_MESSAGES,
    activeMessage: -1,
  },
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB_NOTIFICATION:
      return {
        ...state,
        activeTab: action.payload,
        systemMessages: {
          ...state.systemMessages,
          activeMessage: -1,
        },
      };
    case TOGGLE_CONTENT_NOTIFICATION:
      return {
        ...state,
        systemMessages: {
          ...state.systemMessages,
          activeMessage: state.systemMessages.activeMessage === action.payload ? -1 : action.payload,
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
