import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

// import OneSignal from 'react-onesignal'; // Import OneSignal

/**
 * Компонент Analytics отвечает за подключение и инициализацию сценариев аналитики, таких как
 * Google Analytics, Google Tag Manager и Facebook Pixel и OneSignal.
 */
export const Analytics = () => {
  // Получение идентификаторов аналитики из глобального состояния с использованием хука useSelector
  // const facebookPixelId = useSelector((state) => state.api.modules?.facebookPixel);
  const facebookPixelId = useSelector((state) => state?.settings?.facebookPixel);

  // const googleAnalyticsId = useSelector((state) => state.api.modules?.googleAnalytics);
  // const googleTagManagerId = useSelector((state) => state.api.modules?.googleTagManager);
  // // const oneSignalAppId = useSelector((state) => state.api.modules?.oneSignal);
  // const jivosite = useSelector((state) => state.api.supportNetworks?.jivosite);
  // const yMetrikaId = useSelector((state) => state.api.modules?.yMetrika);
  // const googleCaptcha = useSelector((state) => state.api.modules?.googleCaptcha);

  // useLayoutEffect(() => {
  //   if (googleCaptcha) {
  //     // Load reCAPTCHA script dynamically
  //     const script = document.createElement('script');
  //     script.src = `https://www.google.com/recaptcha/api.js?render=${googleCaptcha}`;
  //     script.async = true;

  //     // Append the script to the document body
  //     document.body.appendChild(script);

  //     // Clean up when the component is unmounted
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [googleCaptcha]);

  // useLayoutEffect(() => {
  //   if (yMetrikaId) {
  //     // Инициализация Yandex.Metrika
  //     (function (m, e, t, r, i, k, a) {
  //       m[i] =
  //         m[i] ||
  //         function () {
  //           (m[i].a = m[i].a || []).push(arguments);
  //         };
  //       m[i].l = 1 * new Date();
  //       for (var j = 0; j < document.scripts.length; j++) {
  //         if (document.scripts[j].src === r) {
  //           return;
  //         }
  //       }
  //       k = e.createElement(t);
  //       a = e.getElementsByTagName(t)[0];
  //       k.async = 1;
  //       k.src = r;
  //       a.parentNode?.insertBefore(k, a);
  //     })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  //     // eslint-disable-next-line
  //     ym(yMetrikaId, 'init', {
  //       clickmap: true,
  //       trackLinks: true,
  //       accurateTrackBounce: true,
  //       webvisor: true,
  //     });
  //   }
  // }, [yMetrikaId]);

  // useLayoutEffect(() => {
  //   if (jivosite) {
  //     // Создаем элемент script
  //     const script = document.createElement('script');

  //     // Установка атрибута для загрузки скрипта
  //     script.src = `//code.jivo.ru/widget/${jivosite}`;
  //     script.async = true;

  //     // Добавил элемент script в конец body
  //     document.body.appendChild(script);

  //     // Очистить ресурсы, когда компонент размонтируется
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [jivosite]);

  // useLayoutEffect(() => {
  //   const initializeOneSignal = async () => {
  //     // Инициализация OneSignal
  //     await OneSignal.init({
  //       appId: oneSignalAppId, // Идентификатор приложения OneSignal
  //       safari_web_id: 'web.onesignal.auto.6a2e4cfc-4f7f-4e0a-b787-2d0bd3e78806', // Идентификатор приложения OneSignal для Safari
  //       notifyButton: {
  //         enable: true, // Включение кнопки подписки
  //       },
  //     });
  //   };

  //   // Вызов функции инициализации OneSignal
  //   if (oneSignalAppId && window?.location?.protocol === 'https:') initializeOneSignal();
  // }, [oneSignalAppId]);

  // Загрузка и инициализация Google Tag Manager
  // useLayoutEffect(() => {
  //   if (googleTagManagerId) {
  //     // Load and initialize Google Tag Manager
  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`;
  //     document.head.appendChild(script);

  //     script.onload = () => {
  //       (function (w, d, s, l, i) {
  //         w[l] = w[l] || [];
  //         w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //         var f = d.getElementsByTagName(s)[0],
  //           j = d.createElement(s),
  //           // eslint-disable-next-line
  //           dl = l != 'dataLayer' ? '&l=' + l : '';
  //         j.async = true;
  //         j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  //         f.parentNode?.insertBefore(j, f);
  //       })(window, document, 'script', 'dataLayer', googleTagManagerId);
  //     };

  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }
  // }, [googleTagManagerId]);

  // Загрузка и инициализация Google Analytics
  // useLayoutEffect(() => {
  //   if (googleAnalyticsId) {
  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  //     document.head.appendChild(script);

  //     script.onload = () => {
  //       window.dataLayer = window.dataLayer || [];
  //       function gtag() {
  //         window.dataLayer.push(arguments);
  //       }
  //       gtag('js', new Date());
  //       gtag('config', googleAnalyticsId);
  //     };

  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }
  // }, [googleAnalyticsId]);

  // Загрузка и инициализация Facebook Pixel
  useLayoutEffect(() => {
    if (facebookPixelId) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode?.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      window.fbq('init', facebookPixelId);
      window.fbq('track', 'PageView');

      // Добавление <noscript> для Facebook Pixel
      const noscriptImg = document.createElement('img');
      noscriptImg.height = 1;
      noscriptImg.width = 1;
      noscriptImg.style.display = 'none';
      noscriptImg.src = `https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`;
      document.body.appendChild(noscriptImg);
    }
  }, [facebookPixelId]);
  // Компонент не возвращает JSX, поэтому возвращаем пустое значение

  return;
};
