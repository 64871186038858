export function translateField(name, fieldPath, words, rename = true) {
  name = name?.toLowerCase();
  const keys = fieldPath.split('.');

  // Check server locale for 'name' first
  if (words?.server && words?.server[name]) {
    return rename ? `${words?.server[name]}` : words?.server[name];
  }

  // Iterate over only the local locale if not found on server
  for (const locale of ['local']) {
    let currentObj = words[locale];

    for (const key of keys) {
      if (currentObj && currentObj[key]) {
        currentObj = currentObj[key];
      } else {
        currentObj = null;
        break;
      }
    }

    if (currentObj && currentObj[name]) {
      if (rename) {
        return `${currentObj[name]}`;
      } else {
        return currentObj[name];
      }
    }
  }

  // Fallback: if not found anywhere, use specified rename or default behavior
  return rename ? `lang->${name}` : name?.replace(/_/g, ' ');
}
