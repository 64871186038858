import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Page from '../_Page/Page';
import { Layout } from '../_Layout/Layout';

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  margin: 5vh auto;

  .error-code {
    font-size: 100px;
    font-weight: bold;
    color: #ff6666;
  }

  .error-message {
    font-size: 24px;
    color: #fff;
  }

  .home-link {
    margin-top: 40px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }

  .home-link:hover {
    background-color: #45a049;
  }
`;

export const NotFound404 = () => {
  return (
    <Page
      navigate={{
        header: false,
        banner: false,
        menu: false,
        filter: false,
        footer: false,
      }}
    >
      <Layout>
        <NotFound className={'not-found'}>
          <div className="error-code">404</div>
          <div className="error-message">Page Not Found</div>
          <Link to="/" className="home-link">
            Go to Home
          </Link>
        </NotFound>
      </Layout>
    </Page>
  );
};
