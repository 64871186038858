import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import ReactInlineSvg from 'react-inlinesvg';

import { getWords } from '../../store/actions';
import { translateField } from '../../shared/utils';
import { useOutsideClick } from '../../hooks';

/**
 * Note: Компонент ButtonLanguages - кнопка для выбора языка интерфейса.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {string} props.direction - Направление отображения (например, 'left', 'right' и т. д.).
 * @returns {JSX.Element} - Возвращает разметку компонента ButtonLanguages.
 */

export const ButtonLanguages = ({ direction }) => {
  const dispatch = useDispatch();

  const api = useSelector((state) => state.api);
  const words = useSelector((state) => state.words);
  const token = localStorage.getItem('user-token');
  const ref = useRef(null);

  const langLocalStorage = localStorage.getItem('current-lang');
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    if (token) return api?.language;
    else {
      if (langLocalStorage) return langLocalStorage;
      else return api?.language;
    }
  });
  const [opened, setOpened] = useState(false);

  const handleChangeLanguage = useCallback(
    (lang) => {
      localStorage.setItem('current-lang', lang);
      setCurrentLanguage(lang);
      dispatch(getWords(lang)); // Получаем слова на выбранном языке
      setOpened(false); // Закрываем выпадающий список
    },
    [dispatch, setOpened],
  );

  useEffect(() => {
    if (token) setCurrentLanguage(api?.language);
    else {
      if (langLocalStorage) setCurrentLanguage(langLocalStorage);
      else setCurrentLanguage(api?.language);
    }
  }, [api.language, langLocalStorage, token]);

  const languagesList = useMemo(() => {
    return api?.languages?.map((lang) => (
      <div key={lang} className={`flag`} onClick={() => handleChangeLanguage(lang)}>
        <ReactInlineSvg className="image" src={`/images/icons/lang/${lang}.svg`} />
        <div className="name"> {translateField(lang, '', words, false) ?? lang}</div>
      </div>
    ));
  }, [api.languages, handleChangeLanguage, words]);

  const languageButton = useMemo(() => {
    return (
      <React.Fragment>
        <div className="trigger" onClick={() => api.languages.length > 1 && setOpened(!opened)}>
          <div className={`flag ${currentLanguage}`}>
            <ReactInlineSvg className="image" src={`/images/icons/lang/${currentLanguage}.svg`} />
            <span className="name">
              {translateField(currentLanguage, '', words, false)}
              {api?.languages?.length > 1 && <ReactInlineSvg className="arrow" src="/images/icons/arrow__down--white.svg" title="lang" desciption="arrow" />}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }, [api.languages, currentLanguage, opened, words]);

  useOutsideClick(ref, () => setOpened(false), opened);

  if (!api.languages || api.languages.length <= 1) return null;

  return (
    <React.Fragment>
      <div ref={ref} className={classNames('button-language')} data-opened={opened} data-direction={direction}>
        {languageButton}
        {api?.languages && api.languages?.length > 1 && <div className="list">{languagesList}</div>}
      </div>
    </React.Fragment>
  );
};
