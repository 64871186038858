import {
  CLOSE_GAME,
  CLOSE_GAME_ERROR,
  FAVORITE_GAME_ERROR,
  GET_GAME_HISTORY,
  OPEN_GAME,
  OPEN_GAME_ERROR,
  SET_CLOSE_GAME,
  SET_FAVORITE_GAME,
  SET_OPEN_GAME,
  RESET_CLOSED_GAME_ID,
} from '../actions/actionTypes';

const initialState = {
  gameClose: null,
  openGame: null,
  gameHistory: null,
  gameError: null,
  openGameError: null,
  selectedProvider: null,
  favoriteGames: null,
  favoriteGameError: null,
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_GAME || GET_GAME_HISTORY || OPEN_GAME:
      return {
        ...state,
      };
    case SET_CLOSE_GAME:
      return {
        ...state,
        gameClose: {
          ...action.payload,
          closedGameId: action.gameId,
          closedGameProvider: action.provider,
        },
        openGame: null,
        openGameError: null,
      };
    case SET_FAVORITE_GAME:
      return {
        ...state,
        favoriteGames: action.payload,
      };
    case SET_OPEN_GAME:
      return {
        ...state,
        openGame: {
          ...action.payload,
          provider: action.provider,
        },
        gameClose: null,
      };

    case CLOSE_GAME_ERROR:
      return {
        ...state,
        gameError: action.payload,
      };
    case FAVORITE_GAME_ERROR:
      return {
        ...state,
        favoriteGameError: action.payload,
      };
    case OPEN_GAME_ERROR:
      return {
        ...state,
        openGameError: action.payload,
      };
    case RESET_CLOSED_GAME_ID:
      return {
        ...state,
        gameClose: action.payload,
      };

    default:
      return state;
  }
};

export default gameReducer;
