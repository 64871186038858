import React from 'react';
import classNames from 'classnames';

export const Layout = ({ className, children }) => {
  return (
    <div
      className={classNames('container layout-container', {
        [`layout-container-${className}`]: className,
      })}
    >
      {children}
    </div>
  );
};
