import { SET_SEARCH_GAMELIST } from '../actions/actionTypes';

const initialState = {
  games: [],
  query: '',
};

const searchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_GAMELIST:
      return {
        ...state,
        games: action.payload?.games,
        query: action.payload?.query,
      };
    default:
      return state;
  }
};
export default searchResultReducer;
