import { SET_TOURNAMENTS, SET_TOURNAMENTS_LOADING, SET_TOURNAMENTS_ERROR } from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
  error: null,
};

const tournamentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOURNAMENTS:
      return {
        ...state,
        list: action.payload,
        error: null,
      };
    case SET_TOURNAMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_TOURNAMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default tournamentsReducer;
