import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 100px;

  @media (max-width: 776px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3 {
    color: #fff;
  }

  p {
    color: #fff;
  }
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

const Item = styled.div``;

const ItemTitle = styled.h3`
  color: #f2fbf2;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 10px;

  @media (max-width: 776px) {
    font-size: 18px;
  }
`;

const Description = styled.pre`
  color: #f2fbff;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 15px;
  white-space: pre-wrap;
`;

const TermsAndConditions = () => {
  const words = useSelector((state) => state.words);
  const termsList = useSelector((state) => state.settings?.termsList) || [];

  return (
    <Container id="termsAndConditions">
      <h1>{translateField('terms_title', 'terms', words)}</h1>
      <h2>{translateField('terms_subtitle', 'terms', words)}</h2>
      <TermsContainer>
        {termsList.map((term, i) => (
          <Item key={i}>
            <ItemTitle>{translateField(term.title, 'terms', words)}</ItemTitle>
            <Description>{translateField(term.text, 'terms', words)}</Description>
          </Item>
        ))}
        <div>Text</div>
      </TermsContainer>
    </Container>
  );
};

export default TermsAndConditions;
