import { GET_INFO, GET_INFO_ERROR, SET_INFO, SET_PAGE_TITLE } from '../actions/actionTypes';

const initialState = {
  pageTitle: null,
  info: null,
  infoError: null,
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFO:
      return {
        ...state,
      };
    case SET_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case GET_INFO_ERROR:
      return {
        ...state,
        infoError: action.payload,
      };
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    default:
      return state;
  }
};

export default infoReducer;
