import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 100px;

  @media (max-width: 776px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3 {
    color: #fff;
  }

  p {
    color: #fff;
  }
`;

const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

const Item = styled.div``;

const ItemTitle = styled.h3`
  color: #f2fbf2;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 10px;

  @media (max-width: 776px) {
    font-size: 18px;
  }
`;

const Description = styled.pre`
  color: #f2fbff;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin-top: 15px;
  white-space: pre-wrap;
`;

const Faq = () => {
  const words = useSelector((state) => state.words);
  const faqList = useSelector((state) => state.settings?.faqList) || [];
  const title = translateField('faqs_title', 'faqs', words);
  const subtitle = translateField('faqs_subtitle', 'faqs', words);

  return (
    <Container id="faq">
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <PrivacyContainer>
        {faqList.map((faq, i) => (
          <Item key={i}>
            <ItemTitle>{translateField(faq.title, 'faqs', words)}</ItemTitle>
            <Description>{translateField(faq.text, 'faqs', words)}</Description>
          </Item>
        ))}
      </PrivacyContainer>
    </Container>
  );
};

export default Faq;
