import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { translateField } from '../../shared/utils';

export const AgeVerificationModal = ({ onAgeVerification }) => {
  const words = useSelector((state) => state.words);
  const [viewError, setViewError] = useState(false);

  const handleVerification = (isVerified) => {
    onAgeVerification(isVerified);
  };

  const handleConfirm = () => {
    handleVerification(true);
    setViewError(false);
  };

  const handleDeny = () => {
    handleVerification(false);
    setViewError(true);
  };

  return (
    <div className="age-verification-modal">
      <div className="age-verification-modal_content">
        <h2>{translateField('age_verify_title', 'age_verify', words)}</h2>
        <p>{translateField('age_verify_text', 'age_verify', words)}</p>

        <div
          className="error"
          style={{
            opacity: viewError ? 1 : 0,
          }}
        >
          <span>{translateField('age_verify_error', 'age_verify', words)}</span>
        </div>

        <div className="buttons-container">
          <button className="confirm" onClick={handleConfirm}>
            {translateField('age_verify_confirm', 'age_verify', words)}
          </button>
          <button className="cancel" onClick={handleDeny}>
            {translateField('age_verify_cancel', 'age_verify', words)}
          </button>
        </div>
      </div>
    </div>
  );
};
