import React from 'react';
import LandingLayout from '../../components/LandingLayout/LandingLayout';
import Contacts from './Contacts';

const ContactsPage = () => {
  return (
    <LandingLayout>
      <Contacts />
    </LandingLayout>
  );
};

export default ContactsPage;
