import styled from 'styled-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import FullForm from '../_FullForm/FullForm';
import Page from '../../pages/_Page/Page';
import Footer from '../_Footer/Footer';
import Header from '../_Header/Header';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  justify-content: center;
  align-items: center;

  @media (max-width: 776px) {
    margin-top: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1140px;

  @media (max-width: 776px) {
    width: 100%;
    padding: 0 2.5%;
  }

  @media (max-width: 1139px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const LandingLayout = ({ children }) => {
  const [showRegBlock, setShowRegBlock] = useState(false);

  useEffect(() => {
    const isFormEnabled = window?.settings?.is_form_open;
    const isFormClosedByUser = window?.settings?.save_closed_form_state ? localStorage.getItem('setOpenForm') === 'false' : false;
    const isOpen = isFormEnabled && !isFormClosedByUser;

    if (isOpen) {
      setShowRegBlock(true);
    }
  }, []);

  useEffect(() => {
    if (showRegBlock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [showRegBlock]);

  const newElement = React.cloneElement(children, {
    setShowRegBlock,
  });

  return (
    <Page>
      <Container
        className={classNames('roulette-section', {
          'roulette-section--show-form': showRegBlock,
        })}
      >
        <Wrapper>
          <Header />
          {newElement}
        </Wrapper>
        {showRegBlock && <FullForm setShowRegBlock={setShowRegBlock} />}
        <Footer />
      </Container>
    </Page>
  );
};

export default LandingLayout;
