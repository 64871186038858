import LandingLayout from '../../components/LandingLayout/LandingLayout';
import Bonuses from './Bonuses';

const BonusesPage = () => {
  return (
    <LandingLayout>
      <Bonuses />
    </LandingLayout>
  );
};

export default BonusesPage;
