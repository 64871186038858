import { WALLET_ADD, FETCH_DATA, FETCH_DATA_ERROR, UPDATE_DATA, WALLET_ADD_LOADING } from '../actions/actionTypes';

const initialState = {
  loading: false,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
      };
    case WALLET_ADD_LOADING:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case WALLET_ADD:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
      };
    // Другие обработчики действий...
    default:
      return state;
  }
};

export default walletReducer;
