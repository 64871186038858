import LandingLayout from '../../components/LandingLayout/LandingLayout';
import Payments from './Payments';

const PaymentsPage = () => {
  return (
    <LandingLayout>
      <Payments />
    </LandingLayout>
  );
};

export default PaymentsPage;
