import {
  GET_ACTIVE_GAMELIST,
  GET_ACTIVE_POPUP,
  GET_WINDOW_SIZE,
  SET_ACTIVE_INPUT,
  SET_VARIABLE_GAMECARD,
  SET_OPEN_MENU,
  SET_OPEN_NOTIFICATIONS,
  GET_WALLET_QUICK_ACCESS,
  SET_HEADER,
  SET_NAVIGATION,
  SET_HANDLE_SCROLL,
  SET_HANDLE_BONUS,
  SET_HANDLE_PATH_BONUS,
} from '../actions/actionTypes';

const windowSize = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const initialState = {
  openMenu: false,
  openNotifications: false,
  activeGameList: checkLastProvider(),
  activeInput: '',
  activePopup: {
    active: false,
    name: '',
  },
  bonus: null,
  bonusPath: null,
  initial: false,
  size: {
    width: windowSize.width,
    height: windowSize.height,
    mobile: isMobile(windowSize),
    landscape: isLandscape(windowSize),
    device: isMobile(windowSize) ? 'mobile' : 'desktop',
    orientation: isLandscape(windowSize) ? 'horizontal' : 'vertical',
    sliderContainer: null,
  },
  variableGameCard: true,
  walletQuickAccess: {},
  header: {
    height: 0,
    visible: null,
  },
  navigate: {
    height: 0,
    visible: null,
  },
  handleScroll: {
    section: null,
  },
};

function checkLastProvider() {
  let storage = localStorage.getItem('lastProvider');
  if (storage != null && storage !== 'search') {
    return storage;
  } else if (window.settings.defaultGamesList) {
    return window.settings.defaultGamesList;
  } else {
    return 'home';
  }
}

function isMobile(param) {
  return param.width <= 992;
}

function isLandscape(a) {
  return a.width > a.height;
}

const handlingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload,
      };
    case SET_OPEN_NOTIFICATIONS:
      return {
        ...state,
        openNotifications: action.payload,
      };

    case GET_ACTIVE_POPUP:
      return {
        ...state,
        activePopup: action.payload,
      };
    case GET_ACTIVE_GAMELIST:
      return {
        ...state,
        activeGameList: action.payload,
      };
    case GET_WINDOW_SIZE:
      return {
        ...state,
        size: {
          ...action.payload,
          mobile: isMobile(action.payload),
          landscape: isLandscape(action.payload),
          device: isMobile(action.payload) ? 'mobile' : 'desktop',
          orientation: isLandscape(action.payload) ? 'horizontal' : 'vertical',
        },
      };
    case SET_ACTIVE_INPUT:
      return {
        ...state,
        activeInput: action.payload,
      };
    case SET_VARIABLE_GAMECARD:
      return {
        ...state,
        variableGameCard: action.payload,
      };
    case GET_WALLET_QUICK_ACCESS:
      return {
        ...state,
        walletQuickAccess: action.payload,
      };
    case SET_HEADER:
      return {
        ...state,
        header: {
          ...state.header,
          ...action.payload,
        },
      };
    case SET_NAVIGATION:
      return {
        ...state,
        navigate: {
          ...state.header,
          ...action.payload,
        },
      };
    case SET_HANDLE_SCROLL:
      return {
        ...state,
        handleScroll: action.payload,
      };
    case SET_HANDLE_BONUS:
      return {
        ...state,
        bonus: action.payload.bonus,
        initial: action.payload.initial,
      };
    case SET_HANDLE_PATH_BONUS:
      return {
        ...state,
        bonusPath: action.payload,
      };
    default:
      return state;
  }
};

export default handlingReducer;
