import axios from 'axios';

axios.defaults.baseURL = window.location.origin;
axios.defaults.headers.post['Content-Type'] = 'application/json';
export const baseApiPost = ({ api, params }) =>
  axios
    .post(api, params)
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e));

export const baseApiGet = ({ api, params }) =>
  axios
    .get(api, { params: params })
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e));

export const baseApiPut = (api, params) =>
  axios
    .put(api, params)
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e));

export const baseApiDelete = (api, params) =>
  axios
    .delete(api, params)
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e));
