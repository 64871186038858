import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import { useSelector } from 'react-redux';
import { translateField } from '../../../shared/utils';

const pointerProps = {
  src: '/images/wheel/choose.png',
  style: {
    transform: 'rotate(48deg)',
    width: '30%',
    right: '-3%',
    top: '0',
  },
};

const CustomWheel = ({ prizeIndex, data, attemptCount, onStartSpinning, onStopSpinning }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const words = useSelector((state) => state.words);

  return (
    <div className="roulette-section__content__spin__custom-wheel">
      <Wheel
        innerBorderColor="white"
        spinDuration={0.3}
        radiusLineColor="#222034"
        radiusLineWidth={2}
        innerBorderWidth={2}
        prizeNumber={prizeIndex}
        data={data}
        textColors={['white']}
        mustStartSpinning={isSpinning}
        textDistance={62}
        onStopSpinning={() => {
          onStopSpinning();
          setIsSpinning(false);
        }}
        pointerProps={pointerProps}
        fontSize={18}
      />
      <button
        disabled={!attemptCount || isSpinning}
        style={!attemptCount ? { filter: 'grayscale(1)' } : null}
        onClick={() => {
          onStartSpinning();
          setIsSpinning(true);
        }}
        className="roulette-section__content__spin__button"
      >
        <img className="rroulette-section__content__spin__button-image" src="/images/wheel/main.svg" alt="wheel" />
        <span>{translateField('spin', 'roulette', words, true)}</span>
      </button>
    </div>
  );
};
export default CustomWheel;
