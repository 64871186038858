import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';

const HelmetComponent = () => {
  const domain = window.location.hostname;
  const metaFromRedux = useSelector((state) => state.meta);

  useEffect(() => {
    if (metaFromRedux.title || metaFromRedux.description || metaFromRedux.keywords) {
      // Update localStorage only if metaFromRedux contains meaningful data
      localStorage.setItem('meta', JSON.stringify(metaFromRedux));
    }
  }, [metaFromRedux]);

  // Get data from localStorage and memoize it
  const metaFromLocalStorage = useMemo(() => {
    return JSON.parse(localStorage.getItem('meta')) || {};
  }, []);

  const getMeta = useMemo(() => {
    // Combine data from Redux and localStorage, prefer Redux if available
    const meta = {};
    meta.title = metaFromRedux?.title || metaFromLocalStorage?.title || domain;
    meta.description = metaFromRedux?.description || metaFromLocalStorage?.description || '';
    meta.keywords = metaFromRedux?.keywords || metaFromLocalStorage?.keywords || '';
    meta.content = metaFromRedux?.content || metaFromLocalStorage?.content || '';

    return meta;
  }, [metaFromRedux, metaFromLocalStorage, domain]);

  return (
    <Helmet>
      <title>{getMeta?.title || domain}</title>
      <meta name="description" content={getMeta?.description} />
      <meta name="keywords" content={getMeta?.keywords} />
      <meta name="content" content={getMeta?.content} />
    </Helmet>
  );
};

export default HelmetComponent;
