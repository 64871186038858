import { lobbyApiPost, translationApiGet } from '../../api';
import { baseApiGet } from '../../api/baseApi';
import {
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_INFO,
  ACTIVATE_BONUS,
  BONUS_DETAILS_INFO,
  BONUS_HISTORY_ERROR,
  CANCEL_BONUS,
  CLEAR_INTERVAL_INFO,
  CLOSE_GAME,
  CLOSE_GAME_ERROR,
  FAVORITE_GAME,
  FAVORITE_GAME_ERROR,
  FETCH_DATA_ERROR,
  GAME_HISTORY_ERROR,
  GET_ACTIVE_GAMELIST,
  GET_ACTIVE_POPUP,
  GET_BALANCE,
  GET_BALANCE_ERROR,
  GET_BONUS_HISTORY,
  GET_BUILD_BLOCKS,
  GET_GAME_HISTORY,
  GET_INFO_ERROR,
  GET_INFO_GAME,
  GET_IP_ERROR,
  GET_IP_SUCCESS,
  GET_PAYMENT,
  GET_PAYMENT_DEPOSIT,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_ERROR_DEPOSIT,
  GET_PAYMENT_ERROR_WITHDRAW,
  GET_PAYMENT_WITHDRAW,
  GET_TECHNICAL_DATA,
  GET_USERAGENT_SUCCESS,
  GET_VERIVICATION_ERROR,
  GET_WALLET_QUICK_ACCESS,
  GET_WINDOW_SIZE,
  GET_WORDS_ERROR,
  OPEN_GAME,
  OPEN_GAME_ERROR,
  PASSWORD_RESET_ADD,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_LOADING,
  RESET_CLOSED_GAME_ID,
  SET_ACTIVE_FORM,
  SET_ACTIVE_INPUT,
  SET_ACTIVE_TAB_NOTIFICATION,
  SET_AXIOS_ERROR,
  SET_BALANCE,
  SET_BONUS_HISTORY,
  SET_CLOSE_GAME,
  SET_CURRENT_USER_TOKEN,
  SET_FAVORITE_GAME,
  SET_GAME_HISTORY,
  SET_HANDLE_BONUS,
  SET_HANDLE_SCROLL,
  SET_HEADER,
  SET_LOCAL_WORDS,
  SET_NAVIGATION,
  SET_NOTIFICATION_READ,
  SET_NOTIFICATION_READ_ERROR,
  SET_OPEN_GAME,
  SET_OPEN_MENU,
  SET_OPEN_NOTIFICATIONS,
  SET_PAGE_METADATA,
  SET_PAGE_TITLE,
  SET_PAYMENT,
  SET_PAYMENT_DEPOSIT,
  SET_PAYMENT_WITHDRAW,
  SET_SEARCH_GAMELIST,
  SET_VARIABLE_GAMECARD,
  SET_VERIVICATION,
  SET_TOURNAMENTS,
  SET_TOURNAMENTS_LOADING,
  SET_TOURNAMENTS_ERROR,
  SET_WORDS,
  SIGNIN_TYPE,
  SIGNIN_TYPE_ERROR,
  SIGNIN_TYPE_SUCCESS,
  SIGNOUT_TYPE,
  SIGNOUT_TYPE_ERROR,
  SIGNOUT_TYPE_SUCCESS,
  SIGNUP_PHONE_NUMBER,
  SIGNUP_TYPE,
  SIGNUP_TYPE_ACTIVATION,
  SIGNUP_TYPE_ERROR,
  SIGNUP_TYPE_SUCCESS,
  TOGGLE_CONTENT_NOTIFICATION,
  UPDATE_DATA,
  UPDATE_DATA_LOADING,
  WALLET_ADD,
  WALLET_ADD_ERROR,
  WALLET_ADD_LOADING,
  SET_HANDLE_PATH_BONUS,
  ACCOUNT_UPDATE_FINISHED,
  GET_CHANGE_LANGUAGE,
} from './actionTypes';

export const fetchData = (page, isTechnical) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DATA_LOADING,
      payload: true,
    });
    const token = localStorage.getItem('user-token');
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const isFirstVisit = localStorage.getItem('user-first-visit');
    const key = localStorage.getItem('cmd-key');
    const lang = localStorage.getItem('current-lang');

    const params = {
      key,
      page: page ?? '/home',
      ...(token ? { token } : {}),
      cmd: 'landing',
      language: lang,
    };
    /*if (token && token !== 'undefined') {
      Object.assign(params, { token });
    }*/

    if (isFirstVisit !== '0') {
      await Object.assign(params, { info: { agent, ip } });
    }

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.content?.token) {
      localStorage.setItem('user-token', data.content.token);
    }

    if (data.error) {
      dispatch({
        type: FETCH_DATA_ERROR,
        payload: { error: data.error },
      });
    }

    if (page?.includes('/bonuses/')) {
      dispatch({
        type: BONUS_DETAILS_INFO,
        payload: data.content,
      });
    }

    if (!isTechnical)
      dispatch({
        type: SET_PAGE_METADATA,
        payload: data?.content?.page?.info,
      });
    dispatch({
      type: UPDATE_DATA,
      payload: data.content,
    });
    if (isTechnical)
      dispatch({
        type: GET_TECHNICAL_DATA,
        payload: data.content?.page?.info,
      });
    dispatch({
      type: UPDATE_DATA_LOADING,
      payload: false,
    });
  };
};

export const buildBlocks = (url) => {
  const token = localStorage.getItem('user-token');

  const params = {
    cmd: 'getPage',
    page: url,
    token,
  };

  return async (dispatch) => {
    const response = await lobbyApiPost({ params });

    const data = await response;

    dispatch({
      type: GET_BUILD_BLOCKS,
      payload: data.content.page.blocks,
    });
  };
};

export function loadLocalTranslations(lang) {
  const translationFilePath = `/lang/${lang}/translation.json`;
  return fetch(translationFilePath)
    .then((response) => {
      return response.json();
    })
    .catch();
}

export function getWords(lang) {
  return async (dispatch) => {
    if (!lang) return;

    try {
      const [translationApiResponse, localTranslations] = await Promise.all([translationApiGet({ params: { translation: lang } }), loadLocalTranslations(lang)]);

      const translationData = await translationApiResponse;

      if (translationData?.error) {
        dispatch({
          type: GET_WORDS_ERROR,
          payload: translationData.error,
        });
      }

      if (translationData.content) {
        // Сортируем ключи объекта по алфавиту
        const sortedKeys = Object.keys(translationData.content).sort();

        // Создаем новый объект с отсортированными ключами
        const sortedTranslationData = {};
        sortedKeys.forEach((key) => {
          sortedTranslationData[key] = translationData.content[key];
        });

        dispatch({
          type: SET_WORDS,
          payload: sortedTranslationData,
        });

        dispatch({
          type: SET_LOCAL_WORDS,
          payload: localTranslations,
        });

        dispatch({
          type: GET_CHANGE_LANGUAGE,
          payload: lang,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error loading translations:', error);
    }
  };
}

export function setNotificationRead(ids) {
  return async (dispatch) => {
    const key = localStorage.getItem('cmd-key');
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'notificationRead',
      key,
      data: ids,
    };
    if (token && token !== 'undefined') {
      Object.assign(params, { token });
    }
    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data) {
      getInfoRequest();
    }
    if (data?.error) {
      dispatch({
        type: SET_NOTIFICATION_READ_ERROR,
        payload: data.error,
      });
    }
    if (data?.content) {
      dispatch({
        type: SET_NOTIFICATION_READ,
        payload: ids,
      });
    }
  };
}

export function getWindowSize(size) {
  return {
    type: GET_WINDOW_SIZE,
    payload: size,
  };
}

export const setActiveGameList = (activeGameList) => {
  localStorage.setItem('lastProvider', activeGameList);
  return {
    type: GET_ACTIVE_GAMELIST,
    payload: activeGameList,
  };
};

export const setSearchGameList = (gameList, query) => {
  return {
    type: SET_SEARCH_GAMELIST,
    payload: { games: gameList, query },
  };
};

export function getPopup(popup) {
  return {
    type: GET_ACTIVE_POPUP,
    payload: popup,
  };
}

export const signInRequest = ({ form, social, domain }) => {
  return async (dispatch) => {
    dispatch({ type: SIGNIN_TYPE });
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const params = {
      cmd: 'signIn',
      info: { agent, ip },
      ...(!social && form ? { ...form } : {}),
      domain: domain,
    };

    if (social && !form) {
      params.social = {
        network: social.provider,
        token: social.token,
      };
    }

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.content.token) {
      localStorage.setItem('user-token', data.content.token);
      dispatch({
        type: SIGNIN_TYPE_SUCCESS,
        payload: data.content.token,
      });
      dispatch(fetchData());
    }

    if (!social && form.hasOwnProperty('phone')) {
      dispatch({
        type: SIGNUP_PHONE_NUMBER,
        payload: form?.phone,
      });
    }

    if (data.content.activation) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: data.content,
      });
    }

    if (data.content.url) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: 'redirect',
      });

      window.location.href = data.content.url;
    }

    if (data.error) {
      dispatch({
        type: SIGNIN_TYPE_ERROR,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: SIGNIN_TYPE_ERROR,
          payload: '',
        });
      }, 4000);
    }

    dispatch({
      type: SET_CURRENT_USER_TOKEN,
      payload: data.content,
    });

    return data;
  };
};
export const signInWhatsAppRequest = ({ social, domain }) => {
  return async (dispatch) => {
    dispatch({ type: SIGNIN_TYPE });
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const params = {
      cmd: 'signIn',
      info: { agent, ip },
      domain: domain,
      social: social,
    };

    const data = await lobbyApiPost({ params });

    if (data?.content?.token) {
      localStorage.setItem('user-token', data.content.token);
      dispatch({
        type: SIGNIN_TYPE_SUCCESS,
        payload: data.content.token,
      });
      dispatch(fetchData());
    }

    if (data?.content?.activation) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: data.content,
      });
    }

    if (data?.content?.url) {
      window.location.href = data.content.url;
    }

    if (data.error) {
      dispatch({
        type: SIGNIN_TYPE_ERROR,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: SIGNIN_TYPE_ERROR,
          payload: '',
        });
      }, 4000);
    }

    dispatch({
      type: SET_CURRENT_USER_TOKEN,
      payload: data.content,
    });

    return data;
  };
};

export const signUpRequest = ({ form, social, domain }) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_TYPE });
    const key = localStorage.getItem('cmd-key');
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const params = {
      cmd: 'signUp',
      info: { agent, ip },
      data: { key },
      domain: domain,
    };

    if (!social && form.hasOwnProperty('phone')) {
      dispatch({
        type: SIGNUP_PHONE_NUMBER,
        payload: form?.phone,
      });
    }

    if (!social && form) {
      params.data = { ...params.data, ...form };
    }

    if (social && !form) {
      params.social = {
        network: social.provider,
        token: social.token,
      };
    }
    const response = await lobbyApiPost({ params });
    const data = await response;

    if (data.content.token) {
      localStorage.setItem('user-token', data.content.token);
      await dispatch(getInfoRequest());
      dispatch({
        type: SIGNUP_TYPE_SUCCESS,
        payload: data.content.token,
      });
    }

    if (data.content.activation) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: data.content,
      });
    }

    if (data.content.url) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: 'redirect',
      });

      window.location.href = data.content.url;
    }

    if (data.error) {
      dispatch({
        type: SIGNUP_TYPE_ERROR,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: SIGNUP_TYPE_ERROR,
          payload: '',
        });
      }, 4000);
    }
    return data;
  };
};

export const signUpWhatsAppRequest = ({ social, domain }) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_TYPE });
    const key = localStorage.getItem('cmd-key');
    const agent = localStorage.getItem('user-agent');
    const ip = localStorage.getItem('user-ip');
    const params = {
      cmd: 'signUp',
      info: { agent, ip },
      data: { key },
      domain: domain,
      social: social,
    };

    const data = await lobbyApiPost({ params });

    if (data?.content.token) {
      localStorage.setItem('user-token', data.content.token);
      dispatch({
        type: SIGNUP_TYPE_SUCCESS,
        payload: data.content.token,
      });
    }

    if (data?.content.activation) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: data.content,
      });
    }

    if (data?.content.url) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: 'redirect',
      });

      window.location.href = data.content.url;
    }

    if (data.error) {
      dispatch({
        type: SIGNUP_TYPE_ERROR,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: SIGNUP_TYPE_ERROR,
          payload: '',
        });
      }, 4000);
    }
    return data;
  };
};

export const signOutRequest = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');
    dispatch({ type: SIGNOUT_TYPE });

    const params = {
      cmd: 'signOut',
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;
    localStorage.removeItem('user-token');

    if (data.error) {
      dispatch({
        type: SIGNOUT_TYPE_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SIGNIN_TYPE_SUCCESS,
      payload: null,
    });
    dispatch({
      type: SET_CURRENT_USER_TOKEN,
      payload: null,
    });
    dispatch({
      type: SIGNOUT_TYPE_SUCCESS,
      payload: data.content,
    });
    return data;
  };
};

export const getInfoRequest = () => {
  return async (dispatch) => {
    //dispatch({ type: GET_INFO });
    dispatch({ type: CLEAR_INTERVAL_INFO });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'info',
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: GET_INFO_ERROR,
        payload: data.error,
      });
    }

    dispatch({
      type: ACCOUNT_UPDATE_INFO,
      payload: data.content,
    });
  };
};

export const openGameRequest = (gameId, provider, domain, bonus, tournaments) => {
  return async (dispatch) => {
    dispatch({ type: OPEN_GAME });
    const token = localStorage.getItem('user-token');
    const freeSpins = bonus ? bonus.includes('free_spins') : false;
    const promoSpins = bonus ? bonus.includes('promo_free_spins') : false;
    const params = {
      cmd: 'gameOpen',
      gameId,
      token,
      domain,
      ...(freeSpins ? { bonus: 'free_spins' } : {}),
      ...(promoSpins ? { bonus: 'promo_free_spins' } : {}),
      ...(tournaments ? { tournaments } : {}),
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.content?.page?.info) {
      dispatch({
        type: GET_INFO_GAME,
        payload: data.content?.page?.info,
      });
    }
    if (!data.error)
      dispatch({
        type: SET_OPEN_GAME,
        payload: {
          ...data.content,
          closeGame: true,
          game: {
            id: gameId,
            provider: provider,
            ...data.content.game,
          },
        },
      });

    if (data.error)
      dispatch({
        type: OPEN_GAME_ERROR,
        payload: data.error,
      });

    dispatch(fetchData('/game/' + gameId));
  };
};

export const closeGameRequest = (gameId) => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_GAME });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'gameClose',
      gameId,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: CLOSE_GAME_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_CLOSE_GAME,
      payload: data.content,
      gameId,
    });
  };
};

export const resetClosedGameId = (value) => ({
  type: RESET_CLOSED_GAME_ID,
  payload: value,
});

export const favoriteGameRequest = (gameId) => {
  return async (dispatch) => {
    dispatch({ type: FAVORITE_GAME });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'gameFavorite',
      gameId,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: FAVORITE_GAME_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_FAVORITE_GAME,
      payload: data.content,
    });
  };
};

export const getGameHistoryRequest = ({ date }) => {
  return async (dispatch) => {
    dispatch({ type: GET_GAME_HISTORY });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'gameHistory',
      date,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: GAME_HISTORY_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_GAME_HISTORY,
      payload: data.content,
    });
  };
};

export const getBonusHistoryRequest = ({ date }) => {
  return async (dispatch) => {
    dispatch({ type: GET_BONUS_HISTORY });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'bonusHistory',
      date,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data?.error) {
      dispatch({
        type: BONUS_HISTORY_ERROR,
        payload: data?.content.error,
      });
    }

    dispatch({
      type: SET_BONUS_HISTORY,
      payload: data?.content,
    });
  };
};

export const onlinePaymentRequest = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_PAYMENT });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'onlinePayment',
      ...payload,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: GET_PAYMENT_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_PAYMENT,
      payload: data.content,
    });
  };
};

export const onlinePaymentDepositRequest = ({ action, cmd, amount, methodId, bonusNeed, bonus }) => {
  return async (dispatch) => {
    dispatch({ type: GET_PAYMENT_DEPOSIT });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd,
      action,
      bonus,
      amount,
      methodId,
      token,
    };

    if (bonusNeed) {
      params.bonusNeed = bonusNeed;
    }

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data?.error) {
      dispatch({
        type: GET_PAYMENT_ERROR_DEPOSIT,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_PAYMENT_DEPOSIT,
      payload: data?.content,
    });
  };
};

export const onlinePaymentWithdrawRequest = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_PAYMENT_WITHDRAW, payload: true });
    const token = localStorage.getItem('user-token');
    const params = {
      ...payload,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data?.error) {
      dispatch({
        type: GET_PAYMENT_ERROR_WITHDRAW,
        payload: data.error,
      });

      setTimeout(() => {
        dispatch({
          type: GET_PAYMENT_ERROR_WITHDRAW,
          payload: '',
        });
        dispatch({ type: GET_PAYMENT_WITHDRAW, payload: false });
      }, 4000);
    } else {
      dispatch({
        type: SET_PAYMENT_WITHDRAW,
        payload: data?.content,
      });

      setTimeout(() => {
        dispatch({
          type: SET_PAYMENT_WITHDRAW,
          payload: '',
        });
        dispatch({ type: GET_PAYMENT_WITHDRAW, payload: false });
      }, 4000);
    }

    return data;
  };
};

export const balanceHistoryRequest = ({ date }) => {
  return async (dispatch) => {
    dispatch({ type: GET_BALANCE });
    const token = localStorage.getItem('user-token');
    const params = {
      cmd: 'balanceHistory',
      date,
      token,
    };

    const response = await lobbyApiPost({ params });

    const data = await response;

    if (data.error) {
      dispatch({
        type: GET_BALANCE_ERROR,
        payload: data.content.error,
      });
    }

    dispatch({
      type: SET_BALANCE,
      payload: data.content,
    });
  };
};

export const accountUpdateRequest = (config, pageLink) => {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_UPDATE_FINISHED,
      payload: false,
    });
    const token = localStorage.getItem('user-token');
    const agent = localStorage.getItem('user-agent');
    let params;
    if (token) {
      params = {
        cmd: 'accountUpdate',
        token,
      };
    } else {
      params = {
        cmd: 'accountUpdate',
        agent,
      };
    }

    const response = await lobbyApiPost({ params: { ...params, ...config } });

    const data = await response;

    if (data?.error) {
      dispatch({
        type: ACCOUNT_UPDATE_ERROR,
        payload: data.error,
      });
    }

    if (data?.content) {
      dispatch({
        type: ACCOUNT_UPDATE_FINISHED,
        payload: true,
      });
    }

    if (pageLink) dispatch(fetchData(pageLink));
  };
};

export const WalletAddRequest = (config, pageLink) => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');
    const agent = localStorage.getItem('user-agent');
    let params;
    if (token) {
      params = {
        cmd: 'walletAdd',
        token,
      };
    } else {
      params = {
        cmd: 'walletAdd',
        agent,
      };
    }
    dispatch({
      type: WALLET_ADD_LOADING,
    });

    const response = await lobbyApiPost({ params: { ...params, ...config } });

    const data = await response;
    if (data?.error) {
      dispatch({
        type: WALLET_ADD_ERROR,
        payload: data.content.error,
      });
    }

    if (pageLink) dispatch(fetchData(pageLink));

    dispatch({
      type: WALLET_ADD,
      payload: { walletCurrency: data?.content },
    });
  };
};

export const passwordResetRequest = (config) => {
  return async (dispatch) => {
    let params = {
      cmd: 'passwordReset',
    };
    dispatch({
      type: PASSWORD_RESET_LOADING,
    });

    const response = await lobbyApiPost({ params: { ...params, ...config } });
    const data = await response;
    if (data?.error) {
      dispatch({
        type: PASSWORD_RESET_ERROR,
        payload: data.error,
      });
    }

    if (!data.content.message && !data.error) {
      dispatch({
        type: PASSWORD_RESET_ADD,
        payload: data.content,
        resetPasswordSuccess: true,
      });
    } else {
      dispatch({
        type: PASSWORD_RESET_ADD,
        payload: data.content,
        resetPasswordSuccess: null,
      });
    }
  };
};

export const getYourIpRequest = () => {
  return async (dispatch) => {
    if (localStorage.getItem('user-first-visit') !== '1') return;
    const response = await baseApiGet({ api: 'https://api.ipify.org', params: { format: 'json' } });

    const data = await response;
    if (data?.error) {
      dispatch({
        type: GET_IP_ERROR,
        payload: data.error.message,
      });
    }

    localStorage.setItem('user-ip', data.ip);
    dispatch({
      type: GET_IP_SUCCESS,
      payload: data.ip,
    });
  };
};

export function getUserAgent(userAgent) {
  localStorage.setItem('user-agent', userAgent);
  return {
    type: GET_USERAGENT_SUCCESS,
    payload: userAgent,
  };
}

export const setActiveInput = (ref) => {
  return { type: SET_ACTIVE_INPUT, payload: ref };
};

export const setActiveForm = (form, forgot) => {
  return { type: SET_ACTIVE_FORM, payload: { activeForm: form, forgot } };
};

export const setCurrentUserToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CURRENT_USER_TOKEN,
      payload: token,
    });
  };
};

export const setVariableGameCard = (variableGameCard) => {
  return {
    type: SET_VARIABLE_GAMECARD,
    payload: variableGameCard,
  };
};

export const setOpenMenu = (openMenu) => {
  return {
    type: SET_OPEN_MENU,
    payload: openMenu,
  };
};
export const setOpenNotifications = (openNotifications) => {
  return {
    type: SET_OPEN_NOTIFICATIONS,
    payload: openNotifications,
  };
};

export const activatePromocode = async (form) => {
  try {
    let token = localStorage.getItem('user-token');
    const params = {
      cmd: 'promoCode',
      token,
      data: {
        promoCode: form.promocode,
        activate: 1,
      },
    };
    const response = await lobbyApiPost({ params });

    const data = await response;
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getVerificationRequest = ({ cmd, value, code, phone }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');

    const params = {
      cmd,
      token,
      data: {
        ...(value ? { value } : {}),
        ...(code ? { code } : {}),
        ...(phone ? { phone } : {}),
      },
    };

    const response = await lobbyApiPost({ params });

    const responseData = await response;

    if (responseData?.content?.url) {
      dispatch({
        type: SIGNUP_TYPE_ACTIVATION,
        payload: 'redirect',
      });

      window.location.href = responseData.content.url;
    }

    if (responseData.content) {
      dispatch({
        type: SET_VERIVICATION,
        payload: responseData.content,
      });
    } else {
      dispatch({
        type: GET_VERIVICATION_ERROR,
        payload: 'Verification request failed',
      });
    }

    return responseData;
  };
};

export const activateBonus = (token, bonusId) => {
  return async (dispatch) => {
    const params = {
      cmd: 'bonusActivate',
      token,
      data: {
        id: bonusId,
      },
    };

    const response = await lobbyApiPost({ params });

    const responseData = await response;

    if (responseData.success) {
      dispatch({
        type: ACTIVATE_BONUS,
        payload: bonusId,
      });
    } else {
      // Handle bonus activation error
      // You can dispatch an error action here if needed
    }
    dispatch(getInfoRequest());

    return responseData;
  };
};

export const cancelBonus = (token, bonusId) => {
  return async (dispatch) => {
    const params = {
      cmd: 'bonusCancel',
      token,
      data: {
        id: bonusId,
      },
    };

    const response = await lobbyApiPost({ params });

    const responseData = await response;

    if (responseData.success) {
      dispatch({
        type: CANCEL_BONUS,
        payload: bonusId,
      });
    } else {
      // Handle bonus cancellation error
      // You can dispatch an error action here if needed
    }

    dispatch(getInfoRequest());

    return responseData;
  };
};

export const getWalletQuickAccess = (wallet) => {
  return {
    type: GET_WALLET_QUICK_ACCESS,
    payload: wallet,
  };
};

export const setActiveTabNotification = (payload) => {
  return {
    type: SET_ACTIVE_TAB_NOTIFICATION,
    payload,
  };
};

export const toggleContentNotification = (payload) => {
  return {
    type: TOGGLE_CONTENT_NOTIFICATION,
    payload,
  };
};

export const setHeader = (height, visible) => ({
  type: SET_HEADER,
  payload: { height, visible },
});

export const setNavigation = (height, visible) => ({
  type: SET_NAVIGATION,
  payload: { height, visible },
});

export const setAxiosError = (payload) => {
  return {
    type: SET_AXIOS_ERROR,
    payload,
  };
};

export const setHandleScroll = (section, payload) => {
  return {
    type: SET_HANDLE_SCROLL,
    payload: { section, ...payload },
  };
};

export const setTournaments = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('user-token');
    let params;
    params = {
      cmd: 'tournaments',
      token,
    };

    const response = await lobbyApiPost({ params: { ...params } });

    const data = await response;

    if (data) {
      if (data.error) {
        dispatch({
          type: SET_TOURNAMENTS_ERROR,
          payload: data.error,
        });
      } else {
        dispatch({
          type: SET_TOURNAMENTS,
          payload: data.content,
        });

        dispatch({
          type: SET_TOURNAMENTS_LOADING,
        });
      }
    }
  };
};

export const setHandleBonus = (payload) => {
  return {
    type: SET_HANDLE_BONUS,
    payload: { bonus: payload.bonus, initial: payload.initial },
  };
};

export const setHandlePathBonus = (payload) => {
  return {
    type: SET_HANDLE_PATH_BONUS,
    payload,
  };
};

export const setPageTitle = (title) => ({
  type: SET_PAGE_TITLE,
  payload: title,
});
