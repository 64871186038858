import { combineReducers } from 'redux';
import apiReducer from './apiReducer';
import metaReducer from './metaReducer';
import wordsReducer from './wordsReducer';
import settingsReducer from './settingsReducer';
import handlingReducer from './handlingReducer';
import authReducer from './authReducer';
import infoReducer from './infoReducer';
import gameReducer from './gameReducer';
import paymentReducer from './paymentReducer';
import walletReducer from './walletReducer';
import searchResultReducer from './searchResultReducer';
import notificationReducer from './notification';
import bonusReducer from './bonus';
import tournamentsReducer from './tournamentsReducer';

const rootReducer = combineReducers({
  meta: metaReducer,
  api: apiReducer,
  words: wordsReducer,
  settings: settingsReducer,
  searchResult: searchResultReducer,
  handling: handlingReducer,
  auth: authReducer,
  info: infoReducer,
  game: gameReducer,
  payment: paymentReducer,
  wallet: walletReducer,
  notification: notificationReducer,
  bonus: bonusReducer,
  tournaments: tournamentsReducer,
});

export default rootReducer;
